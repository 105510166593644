import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import settings from '../static/settings.json';
import { convertURL2Obj } from '../helpers/helpers.jsx';
import flatsReducer from './flatsReducer';
import filteredFlatsReducer from './filteredFlatsReduces';
import favoritesReducer from './favoritesReducer';
import flybyReducer from './flybyReducer';



function prepareDefaultData() {
   const searchParams = convertURL2Obj();
   let obj = {};
   const filterValue = settings.filter.reduce((acc, el) => {
      acc.push(el.name);
      return acc;
   }, [])
   filterValue.forEach(el => {
      console.log(searchParams[el]);
      if (searchParams[el]) {
         obj[el] = searchParams[el].split('~');
      }
   })
   console.log(obj);
   return obj;
}

function prepareDataForStoring(incomingData, currentState) {
   const { name, type, value, checked } = incomingData; 
   switch (type) {
      case 'checkbox':
         let singleFilterObject = Array.isArray(currentState[name]) ? currentState[name] : [];
         if (checked) singleFilterObject.push(value);
         if (!checked) singleFilterObject = singleFilterObject.filter(el => el !== value);      
         return { [name]: singleFilterObject }
      case 'radio':
         return { [name]: value }
      default:
         break;
   }
}
function prepareRangeForStoring(incomingData) {
   const { name, value } = incomingData;
   return { [name]: value.join('~') };
}
function filterReducer(state = prepareDefaultData(), action) {
   // const history = useHistory();
   // console.log(history);
   switch (action.type) {
     case 'change':
        const dataForState = prepareDataForStoring(action.payload, state);
      // console.log(Object.assign(state, action.payload));
      //  return state;
      console.log( Object.assign(state, dataForState));
       return { ...state,  ...dataForState};
     case 'changeRange':
       return { ...state,  ...prepareRangeForStoring(action.payload)};
      case  'clearFilter':
         return {};
     default:
       return state
   }
 }
//  const filteredStore = createStore(filterReducer);
 const combiner = combineReducers({ flybyReducer, filterReducer, filteredFlatsReducer, flatsReducer, favoritesReducer });
 const store = createStore(combiner, applyMiddleware(thunk));
export default store;
