import { useSelector } from 'react-redux';
import settings  from '../static/settings.json';

const ranges = settings.filter.filter(el => el.type === 'range').map(el => el.name);
export default function useFilterFlats() {
   const flats = useSelector(store => store.flatsReducer);
   const filterData = useSelector(store => store.filterReducer);
   const filterDataInArray = Object.entries(filterData);
   let countFieldForValidating = filterDataInArray.length;
   console.log(ranges);
   return flats.filter((flat) => {
      let validFieldsCount = 0;
      Object.entries(filterData).forEach(([key,value]) => {
         // console.log(value);
         if (ranges.includes(key)) {
            console.log();
            const [min,max] = Array.isArray(value) ? value : value.split('~');
            const flatFilterValue = +flat[key];
            if (flatFilterValue >= +min && flatFilterValue<=+max) validFieldsCount+=1;
            return;
         }
         if (value.length === 0) {
            validFieldsCount+=1;
            return;
         }
         if (!flat[key]) {
            validFieldsCount+=1;
            return;
         }
         if (value.includes(flat[key])) {
            validFieldsCount+=1;
            return;
         }
         
      });

      return countFieldForValidating === validFieldsCount;
   });

}