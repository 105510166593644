import React from "react";
import './icon-delete.scss';
function IconDelete(props) {
    return (
    <svg {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        className="delete-icon"
        >
        <path
            fill="#c2e8ff"
            d="M7.5 34.5V34L3.495 5.929l.002-.429H36.5V6l-3.995 27.929-.003.571z"
        ></path>
        <path
            fill="#788b9c"
            d="M35.99 6l-3.98 27.859-.01.07V34H8v-.071l-.01-.07L4.01 6h31.98M37 5H3v1l4 28v1h26v-1l4-28V5z"
        ></path>
        <path
            fill="#dcd5f2"
            d="M3 5.5a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5h34a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H3z"
        ></path>
        <path
            fill="#8b75a1"
            d="M37 2v3H3V2h34m0-1H3a1 1 0 00-1 1v3a1 1 0 001 1h34a1 1 0 001-1V2a1 1 0 00-1-1z"
        ></path>
        <path
            fill="#dcd5f2"
            d="M10 38.5A2.503 2.503 0 017.5 36v-1.5h25V36c0 1.378-1.121 2.5-2.5 2.5H10z"
        ></path>
        <path
            fill="#8b75a1"
            d="M32 35v1c0 1.103-.897 2-2 2H10c-1.103 0-2-.897-2-2v-1h24m1-1H7v2a3 3 0 003 3h20a3 3 0 003-3v-2z"
        ></path>
        <g className="delete-icon-inner">
            <path
            fill="#4e7ab5"
            d="M27.262 21.773l-1.127-1.902-.705 1.548-1.704-.121 1.127 1.902H21.62l-1.352 1.4L21.62 26h3.24c1.168 0 2.114-.498 2.595-1.364.477-.861.401-1.915-.193-2.863zm-2.674-4.645l-2.134-3.624c-.596-.956-1.478-1.504-2.42-1.504-.941 0-1.822.548-2.435 1.531l-1.338 2.254 1.661-.033.745 1.463 1.323-2.227c.015-.026.031-.049.045-.07l.025.038 2.114 3.592 1.918.486.496-1.906zm-9.432 6.056l1.902-3.311-.495-1.829-1.933.434-1.895 3.298c-.592 1.008-.658 2.067-.179 2.904.479.839 1.421 1.32 2.584 1.32h3.452l-.999-1.4.999-1.4h-3.445l.009-.016z"
            ></path>
        </g>
        </svg>
    );
}

export default IconDelete;
