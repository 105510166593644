import {useEffect, useState, Fragment} from 'react';
import {useNavigate,  Link,  } from 'react-router-dom';
import Canvas from '../components/Canvas/Canvas';
import Curtain from '../components/Curtain/Curtain';
import { convertURL2Obj } from '../helpers/helpers';
import useCustomSearchParams from '../hooks/useCustomSearchParams';
import settings from '../static/settings.json';
/** handler of changing sequences, highlighting flats on renders */
export default function Flyby(props){
    const searchParams = convertURL2Obj();
    const [pending, setPending ]= useState(false);
    const nav = useNavigate();
    const customSearchParams = useCustomSearchParams();
    const defaultControlPoints = (searchParams.flybyType !== undefined) ? settings.flybyList.find(el => searchParams.flybyType === el.id).controlPoint :  settings.flybyList[0].controlPoint;
    const [controlPoints, setControlPoints] = useState(defaultControlPoints);
    const defaultFlyby =  (searchParams.flybyType !== undefined) ? settings.flybyList.find(el => searchParams.flybyType === el.id) : settings.flybyList[0];
    const [activeFlyby, setActiveFlyby] = useState(defaultFlyby);
    useEffect(() => {
        if (searchParams.flybyType === undefined) {
            nav({
            search: '?flybyType='+settings.flybyList[0].id,});
            setActiveFlyby(settings.flybyList[0]);
            console.log(settings.flybyList[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
        <Curtain visible={pending}/>
        {settings.flybyList.map(el => (
            <Fragment key={el.name}>
            <Link 
                key={el.id}
                className={(activeFlyby.id === el.id) ? "btn btn-warning" : "btn btn-primary "}
                onClick={() => {
                    setPending(true);
                    setTimeout(() => {
                        setControlPoints(el.controlPoint);
                        setActiveFlyby(el);
                        setPending(false);
                    }, 1000);
                    
                }}
                to={customSearchParams.getLinkWithSearchParam('flybyType', el.id)}
                >
                {el.id}
            </Link>
            </Fragment>  
        ))}
        {
            controlPoints.map(point => (
                <div key={point} className="btn">{point}</div>
            ))
        }
        <Canvas controlPoints={controlPoints} flyby={activeFlyby.id} imgUrl={activeFlyby.url} numberSlides={activeFlyby.numberSlide}/>
        {/* <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam provident ipsam, alias illo quas accusamus iste itaque possimus ab voluptatem autem veritatis minima recusandae culpa, maxime ratione reiciendis perspiciatis nisi aut, dignissimos dolore distinctio! Maiores sapiente nihil iste soluta perferendis et iure expedita ratione deleniti dolore tempore nobis a perspiciatis consequuntur delectus reprehenderit, nam quisquam harum maxime eum. Dolore sunt, mollitia maxime, ipsa molestias cum reprehenderit voluptate repellat id sapiente soluta a rerum! Illum molestiae dolor doloremque nesciunt obcaecati hic earum in explicabo soluta quasi facere, quaerat ea cupiditate dolorem repudiandae, ipsa esse deleniti optio fugit sint quas cum. Quisquam?</div> */}
        </>
    )
}