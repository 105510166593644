import PropTypes from 'prop-types';
import {Alert} from 'react-bootstrap';
import useCustomSearchParams from '../../hooks/useCustomSearchParams';

 function RadioGroup({elements, onChange, ...props}) {
   const { name } = elements;
   const customSearchParams = useCustomSearchParams();
   const searchParam = customSearchParams.getSearchParam(name) ? customSearchParams.getSearchParam(name).split('~') : [];
   return <>
      <Alert>{elements.name}</Alert>
      {elements.value.map((val) => (
            <input 
            key={val} 
            type="radio" 
            checked={searchParam.includes(val.toString())}
            name={elements.name} 
            onChange={(e) => onChange(e)} value={val}></input>
      ))}
   </>
}

RadioGroup.propTypes = {
   /** Object with key name, value */
   elements: PropTypes.shape({
      name: PropTypes.string,
      /** values to display in input range type */
      value: PropTypes.array
   }),
   /** for transfering current value to parent component  */
   onChange: PropTypes.func
}


RadioGroup.defaultProps = {
   elements: {
      name: 'some',
      value: [1,2,3]
   },
   onChange: () => {}
 };

export default RadioGroup;