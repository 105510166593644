export function addFavourite(data) {
   return {
      type: 'add_fav',
      payload: data
   }
}

export function deleteFavorite(data) {
   return {
      type: 'remove_fav',
      payload: data
   }
}