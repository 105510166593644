function getDefaultFavFlats() {
   const fromStorage = JSON.parse(localStorage.getItem('fav'));
   return fromStorage || [];
}
export default function favoritesReducer(state = getDefaultFavFlats(), action) {
   switch (action.type) {
      case 'add_fav':
         const newState = [...state];
         newState.push(action.payload);
         localStorage.setItem('fav', JSON.stringify(newState))
         return newState;
      case 'remove_fav':
         const filtered = state.filter(el => (el !== action.payload));
         localStorage.setItem('fav', JSON.stringify(filtered))
         return filtered;
      default:
         return state;

   }
}