export default function flybyReducer(state = { some: 'any'}, { type, payload }) {
    switch (type) {
        case 'addFlyby':
            const { sequence, id } = payload;
            const dataToAdd = {       ...state, [id]: sequence     }
            return dataToAdd;
        default:
            return state;
    }
}

/**
 * 
 [name] : {
     id: string,
     first: url,
     sequence: [Array of URL]
 }
 */