
import './curtains.scss';

import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


export default function Curtain({visible}){
    return (
        <div className={visible ? "curtain active" : "curtain"}>
            <CircularProgress />
        </div>
    )
}