import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { convertURL2Obj } from "../helpers/helpers";
import settings from '../static/settings.json';
export default function useCustomSearchParams() {
   const navigation = useNavigate();
   const location = useLocation();
   return {
      set: (key, val) => {
         let currentUrl = convertURL2Obj();
         currentUrl[key] = val;
         navigation({
            search: '?'+Object.entries(currentUrl).map(([key,val]) => `${key}=${val}`).join('&'),
            replace: true
         })
      },
      clearFilterParams() {
         const filterTypes = settings.filter.map(el => el.name);
         let currentUrl = convertURL2Obj();
         filterTypes.forEach(type => delete currentUrl[type]);
         navigation({
            search: '?'+Object.entries(currentUrl).map(([key,val]) => `${key}=${val}`).join('&'),
            replace: true
         })
      },
      getSearchParam(key) {
         let currentUrl = convertURL2Obj();
         return currentUrl[key];
      },
      getLinkWithPathAndSearchParam: (path, key, value) => {
         let currentUrl = convertURL2Obj();
         currentUrl[key] = value;
         return path + '?'+Object.entries(currentUrl).map(([key,val]) => `${key}=${val}`).join('&');
      },
      getLinkWithSearchParam: (key, value) => {
         let currentUrl = convertURL2Obj();
         currentUrl[key] = value;
         return location.pathname + '?'+Object.entries(currentUrl).map(([key,val]) => `${key}=${val}`).join('&');
      },
      getLink: (path) => {
         return path + location.search;
      },
      setFromObject: (object) => {
         let currentUrl = convertURL2Obj();
         Object.entries(object).forEach(([key,value]) => {
            if (typeof value === 'string') {
               currentUrl[key] = value;
               return;
            };
            if (value.join) {
               currentUrl[key] = value.join('~');
            }
         });
         navigation({
            search: '?'+Object.entries(currentUrl).map(([key,val]) => `${key}=${val}`).join('&'),
            replace: true
         })
         
      }

   }
      // if (!selector) return;
      // const query = Object.entries(selector).forEach(([key,value]) => {
      //    if (typeof value === 'string') {
      //       currentUrl[key] = value;
      //       return;
      //    };
      //    currentUrl[key] = value.join('~');
      // });
      // nav({
      //    search: '?'+Object.entries(currentUrl).map(([key,val]) => `${key}=${val}`).join('&'),
      //    replace: true
      // })



}