import React, { useContext} from 'react';
import { ThemeContext } from '../hocs/Context.jsx';
import Card from '../components/card';

import './Floor.css';
import Filter from '../components/Filter/Filter.jsx';
import { Col, Row, Alert } from 'react-bootstrap';
import useFilterFlats from '../hooks/useFilterFlats.jsx';
export default function Floor(){
    // eslint-disable-next-line no-unused-vars
    const [context, setContext] = useContext(ThemeContext);

    const flats = useFilterFlats();
    return (
    <div className='floor'>
        {(flats.length === 0) && <Alert>Flats not found</Alert>}
        <Row>
            {flats.slice(0,22).map((item, index) => (
                // <Card onClick={() => setContext(context.slice(index+1,context.length - 1))} data={item}/>
                <Col lg={4}  md={6} sm={6}> <Card data={item}/> </Col>
            ))}
        </Row>
        <Filter></Filter>
    </div>
    )
}