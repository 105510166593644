import { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useDispatch } from 'react-redux';


import './components/link/links.css';

import { ThemeContext, FilteredFlatsContext } from './hocs/Context.jsx';
import Flyby from './pages/Flyby';
import Floor from './pages/Floor';
import Flat from './pages/Flat';
import { Container } from 'react-bootstrap';
import Favorites from './pages/Favourites';
import Header from './components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

function App() {
  const [context, setContext] = useState([]);
  const [filteredFlats, setFilteredFlats] = useState([]);
  const dispatcher = useDispatch();
  useEffect(() => {
    const data = new FormData();
    data.append('action', 'getFlats');
    fetch('https://grand-byrze-wp.smarto.agency/wp-admin/admin-ajax.php', {
      body: data,
      method: 'POST',
    })
      .then(el => el.json())
      .then(res => {
        setContext(res);
        setFilteredFlats(res);
        dispatcher({
          type: 'add',
          payload: res
        })
    })
  }, [])
  return (
    // <Provider store={store}>
      <Container fluid>
        <BrowserRouter>
        <FilteredFlatsContext.Provider value={[filteredFlats, setFilteredFlats]}>
            <ThemeContext.Provider value={[context, setContext]}>
              <Header></Header>
              <Routes>
                <Route path="/" element={<Flyby />} />
                <Route path="/favorites" element={<Favorites/>} />
                <Route path="/floor" element={<Floor />} />
                <Route path="/flat" element={<Flat />} />
              </Routes>
              </ThemeContext.Provider>
          </FilteredFlatsContext.Provider>
        </BrowserRouter>
      </Container>
    // </Provider>
  );
}

export default App;
