import { Alert } from "react-bootstrap"
import useCustomSearchParams from '../../hooks/useCustomSearchParams';



export default function CheckboxGroup({ elements, onChange, ...props }) {
   const { name } = elements;
   const customSearchParams = useCustomSearchParams();
   const searchParam = customSearchParams.getSearchParam(name) ? customSearchParams.getSearchParam(name).split('~') : [];
   
   // console.log(searchParam, 'SEARCHParam');

   if (elements === undefined) return <></>

   return <>
      <Alert>{elements.name}</Alert>
      {elements.values.map((box) => (
         <div key={elements.name+box}>
            <input  
               data-some={searchParam.includes(box)}  
               id={elements.name+box} 
               type="checkbox" 
               name={elements.name} 
               value={box} 
               onChange={onChange}
               checked={searchParam.includes(box.toString())}
            ></input>
            <label htmlFor={elements.name+box} type="checkbox" >{box}</label>
         </div>
      ))}
   </>   
}

// CheckboxGroup.propTypes = {
//    elements: PropTy
// }