import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider(props) {
   const defaultVal = props.elements.value;
   
   const { elements, from, to } = props;
   const [value, setValue] = React.useState(defaultVal ? defaultVal : [from,to]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        size="small"
        name={elements.name}
        min={defaultVal && defaultVal[0]}
        max={defaultVal && defaultVal[1]}
        getAriaValueText={valuetext}
        {...props}
        onChangeCommitted={(evt,value) => props.onChangeCommitted(evt,value, elements.name)}
      />
  );
}
