import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FavouritesContext } from '../hocs/Context.jsx';
import Button from 'react-bootstrap/Button';
import './card.css';
import { Link } from 'react-router-dom';
import useCustomSearchParams from '../hooks/useCustomSearchParams.jsx';
import { addFavourite, deleteFavorite } from '../actions/favouritesActions.jsx';


export default function Card(data){
    // console.log(data);
    const [favFlats, setFavFlats] = useContext(FavouritesContext);
    const { img_big, rooms, area, floor, id } = data.data;
    const isInFavorites = useSelector(store => store.favoritesReducer.includes(id));
    const customSearchParams = useCustomSearchParams();
    const dispatcher = useDispatch();
    return (
        <div {...data} className="s3d-card js-s3d-card" data-id={id} data-key="id">
            <button className="s3d__close js-s3d-card__close"></button>
            <div className="s3d-card__image">
                <img dataSrc={img_big} dataKey="src"/>
                </div>
            <div className="s3d-card__title">
                <div data-key="rooms">Rooms: {rooms}</div>
                <div data-key="area">Area: {area} м<sup>2</sup></div>
            </div>
            <table className="s3d-card__table">
                <tbody>
                    <tr className="s3d-card__row">
                        <td className="s3d-card__name"></td>
                        <td className="s3d-card__value" data-key="id">Number: {id}</td>
                    </tr>
                    <tr className="s3d-card__row">
                        <td className="s3d-card__name"></td>
                        <td className="s3d-card__value" data-key="floor">Floor: {floor}</td>
                    </tr>
                    <tr className="s3d-card__row">
                        <td className="s3d-card__name"></td>
                        <td className="s3d-card__value" data-key="type"></td>
                    </tr>
                </tbody>
            </table>
            <div className="s3d-card__buttons">
                <label arialabel="button" ariaRole="button" data-id={id} data-key="id" className="s3d__favourite js-s3d-add__favourite">
                </label>
                <Button onClick={() => {
                        // setFavFlats(favFlats.includes(id) ? favFlats.filter(el => el!==id) : [...favFlats, id]);
                        isInFavorites ? dispatcher(deleteFavorite(id)) : dispatcher(addFavourite(id)) ;
                    }} 
                    type="button" className="s3d-card__link js-s3d-card__link">
                    {isInFavorites ? 'З улюбленого' : 'В улюблені'}
                </Button>
                <Link  className='btn btn-warning' to={customSearchParams.getLinkWithPathAndSearchParam('/flat', 'id', id)} /* to={'/flat?id='+id}*/>show info </Link>
            </div>
        </div>
    )
}