import {  Row, Col } from 'react-bootstrap'; 
import { NavLink } from 'react-router-dom';
import useCustomSearchParams from '../../hooks/useCustomSearchParams';
export default function Header(props) {
   const customSearchParams = useCustomSearchParams();
   const isActiveLink = ({ isActive }) => isActive ? {color: 'red'} : {color: 'blue'};
   return (
      <Row lg='auto'>
         <Col><NavLink style={isActiveLink} className="headerLink" to={customSearchParams.getLink('/')}>Main</NavLink></Col>
         <Col><NavLink style={isActiveLink} className="headerLink" to={customSearchParams.getLink('/flyby')}>Flyby</NavLink></Col>
         <Col><NavLink style={isActiveLink} className="headerLink" to={customSearchParams.getLink('/floor')}>Flats</NavLink></Col>
         <Col><NavLink style={isActiveLink} className="headerLink" to={customSearchParams.getLink('/favorites')}>Favorites</NavLink></Col>
         <Col><NavLink style={isActiveLink} className="headerLink" to={customSearchParams.getLink('/flat')}>Flat info</NavLink></Col>
      </Row>
   )
}