

import PropTypes from 'prop-types';
import './svg-canvas.scss';



// props: currenturl, polygonPoints, isBlocked, 
export default function SvgCanvas({ currenturl, polygonPoints, isBlocked, ...props }) {
    const eventsProps = Object.entries(props).filter(([ket,value]) => {
        return ket.match(/^on/);
    }).reduce((acc,el) => {
        acc[el[0]] = el[1];
        return acc;
    },{});
    if (!currenturl) return <></>
    return (   
        <>
            {/* <svg preserveAspectRatio='xMidYMid slice' { ...eventsProps } className={isBlocked ? "svg-canvas blocked" : "svg-canvas"} height={window.innerHeight} width={window.innerWidth} viewBox={`0 0 1920 1080`}> */}
            <svg {...eventsProps} preserveAspectRatio='xMidYMid slice' className={isBlocked ? "svg-canvas blocked" : "svg-canvas"} height={window.innerHeight} width={window.innerWidth} viewBox={`0 0 1920 1080`}>
                <image width="100%" height="100%" preserveAspectRatio='xMidYMid slice' href={currenturl} />
                {polygonPoints.map((polygon, index) => (
                    <polygon key={index} points={polygon}/>
                ))}
                {/* <circle r="50" cx="50" cy="50" fill="red" /> */}
            </svg>
            {/*  */}
        </>
    )
}

SvgCanvas.propTypes = {
    /** href of image to show */
    currenturl: PropTypes.string, 
    /** points to transfer it to polygon */
    polygonPoints: PropTypes.array,
    /** Block status */
    isBlocked: PropTypes.bool
}