import { useEffect, useState } from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { Button } from 'react-bootstrap';
import RangeSlider from '../Range/Range';
import './filter.scss';

import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';

import settings from '../../static/settings.json';
import RadioGroup from '../RadioGroup/RadioGroup';
import useCustomSearchParams  from '../../hooks/useCustomSearchParams';
import clearFilter from '../../actions/filterActions.jsx';
import IconDelete from '../IconDelete/IconDelete';

/** Handler for managing data which will be used for filtering */
export default function Filter(props) {
   const [visible, setVisible] = useState(false);
   const filterTypes = settings.filter;
   const dispatch = useDispatch();
   const customSearchParams = useCustomSearchParams();
   const selector = useSelector((store) => store.filterReducer);
   useEffect(() => {
      if (!selector) return;
      customSearchParams.setFromObject(selector);
   }, [selector])

   function onChange(e) {
      dispatch({
         type: 'change',
         payload: { 
            name: e.target.name,
            value: e.target.value,
            checked: e.target.checked,
            type: e.target.type
         }
      });
   }
   function onRangeChange(evt, value, name) {
      console.log(evt);
      dispatch({
         type: 'changeRange',
         payload: { 
            name,
            value,
         }
      });
   }
   return <div className={"filter" + (visible ? ' opened' : '')}>
      <IconDelete onClick={() => {
         customSearchParams.clearFilterParams();
         clearFilter();
      }}/>
      <Button onClick={() => setVisible(!visible)} className={'btn btn-primary filter__call' + (visible ? ' opened' : '')}>{visible ? 'X' : 'Filter'}</Button>
      {filterTypes.filter(el => el.type === 'checkbox').map((filterItem) => (
         <CheckboxGroup onChange={onChange} elements={filterItem}></CheckboxGroup> 
      ))}
      {filterTypes.filter(el => el.type === 'radio').map((filterItem) => {
            return <RadioGroup  elements={filterItem} onChange={onChange}/>
      })}
      {filterTypes.filter(el => el.type === 'range').map((filterItem) => {
         const defaultRanges = customSearchParams.getSearchParam(filterItem.name);
         const defaultFrom = defaultRanges ? defaultRanges.split('~')[0] : undefined;
         const defaultTo = defaultRanges ? defaultRanges.split('~')[1] : undefined;
         // console.log(defaultRanges);
         return (
            <>
               <div>{filterItem.name}</div>
               <RangeSlider  
                  elements={filterItem} 
                  from={defaultFrom} 
                  to={defaultTo} 
                  onChangeCommitted={onRangeChange}
               />
            </>
         )
      })}
   </div>
}