   /** Поочередная смена кадров которые берутся с массива props.controlPoints */
export function fromTo(start, end, changeState = () => {}, maxFrame = 119) {
    let currentPoint = start;
    function increase() {
    if (currentPoint === end+1) return;
    changeState(currentPoint);
    currentPoint = (currentPoint === maxFrame) ? 0 : (currentPoint+1);
    setTimeout(() => {
        increase();
    }, 1000 / 60);

    }
    increase();
};
export function fromToDecrease(start, end, changeState = () => {}, maxFrame = 119) {
    let currentPoint = start;
    function increase() {
    if (currentPoint === end-1) return;
    changeState(currentPoint);
    currentPoint = (currentPoint === 0) ? maxFrame : (currentPoint-1);
    setTimeout(() => {
        increase();
    }, 1000 / 35);

    }
    increase();
};
/** формирование массива, из которого будут братся значения для вывода нужной картинки при перетаскивании */
export function prepareArrayForDragging(prev, current, next, max) {
    let array = [];
    // if (true) {
    if (prev > current) {
    for (let i = prev; i<=max; i++) {
        array.push(i);
    }
    for (let i = 0; i <current; i++) {
        array.push(i);
    }
    for (let i = current; i <= next; i++) {
        array.push(i);
    }
    return array;
    }
    if (next < current) {
    for (let i = prev; i < current; i++) {
        array.push(i);
    }
    for (let i = current; i<=max; i++) {
        array.push(i);
    }
    for (let i = 0; i <=next; i++) {
        array.push(i);
    }
    return array;
    }
    for (let i = prev; i < current; i++) {
        array.push(i);
    }
    for (let i = current; i<=next; i++) {
        array.push(i);
    }
    return array;
}