// import mainStore from './mainStore';
import settings from '../static/settings.json';

function loadAsync(url, count, cb = () => {}, cbOnIteration = () => {}, cbOnFirstLoad = () => {}) {
    let i = 0;
    let arr = [];
    function get() {
       fetch(url+i+'.jpg')
          .then(el => el.blob())
          .then(res => {
             const objectURL = URL.createObjectURL(res);
             if (i >= count) {
                   cb(arr);
                   return;
             }
             i++;
             arr.push(objectURL);
             cbOnIteration(count, arr.length);
             get();
          })
    }
 
    get();
}
export function getFlyby(id = settings.flybyList[0].id) { 
    return (dispatch, getState) => {
        if (getState().flybyReducer[id] || getState().flybyReducer[id] === 'pending') return;
        const { url, numberSlide, controlPoint } = settings.flybyList.find(el => el.id === id);
        if (!getState().flybyReducer[id]) {
            dispatch({
                type: 'addFlyby',
                payload: 'pending',
            })
        }

        fetch(url + controlPoint[0] + '.jpg')
            .then(res => res.blob())
            .then(res => {
               let arr = [];
               const url = URL.createObjectURL(res);
               arr[[controlPoint[0]]] = url;
               dispatch({
                    type: 'addFlyby',
                    payload: {
                        id,
                        sequence: arr,
                    }
                })
            })
        loadAsync(
            url, 
            numberSlide.max, 
            (array) => {
                dispatch({
                    type: 'addFlyby',
                    payload: {
                        id,
                        sequence: array,
                    }
                })
            },
        )
    }
}