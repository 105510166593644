import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from '../components/card.jsx';
import { FavouritesContext, ThemeContext } from '../hocs/Context.jsx';


export default function Favorites(props){
    const favFlats = useSelector(store => store.favoritesReducer);
    const flats = useSelector(store => store.flatsReducer);
    const flatsToShow = flats.filter(flat => {
        return favFlats.includes(flat.id);
    })
    console.log(favFlats);
   if (flatsToShow.length === 0) return <Link to="/floor" className='btn btn-dark'> Flat favourites is empty: go back </Link>
    return (
         <Row>
            {flatsToShow.map(el => <Col lg={3} md={6} sm={6}>  <Card data={el} /> </Col>)}
         </Row>
    )
}