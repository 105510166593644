import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../hocs/Context';
import { Row, Col, Button } from 'react-bootstrap';

const convertURL2Obj = () => {
   let array = window.location.search.replace('?', '').split('&').map(el => el.split('='));
   let obj = {};
   array.forEach(el => obj[el[0]] = el[1]);
   return obj;
 }
 export const convertObj2URL = (obj) => {
   if(isEmpty(obj)) return '';
   
   let accStr = '?'
   for (var prop in obj) {
     accStr += `${prop}=${obj[prop]}&`
   }
   return accStr.slice(0, -1);
 }
 
 function isEmpty(obj) {
     return Object.keys(obj).length === 0;
 }


export default function Flat({ id, ...props }) {
   let history = useNavigate();
   const search = convertURL2Obj();
   const flatId = search.id;
   // eslint-disable-next-line no-unused-vars
   const [context, setContext] = useContext(ThemeContext);
   const flatInfo = context.find(el => el.id === flatId);
   console.log(history);
   if (!flatInfo) return    (
      <>
         <h1> Flat not found</h1>
         <Button onClick={() => history(-1)} variant="info">
            Go back
         </Button>
      </>
   )
   return <Row>
      <Col lg={12}>
         <Button onClick={() => history(-1)} variant="info">
            Go back
         </Button>
      </Col>
      <Col lg={12}>
         <h1>Flat {flatId}</h1>
      </Col>
      <Col>
         <ul>
            <li>Build name: {flatInfo.build}</li>
            <li>Floor: {flatInfo.floor}</li>
            <li>Area: {flatInfo.area}</li>

         </ul>
      </Col>
   </Row>
   
}