import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Row } from "react-bootstrap";
import gsap from 'gsap';
import Controls from "../flyby/Controls/Controls";
import Filter from "../Filter/Filter";
import SvgCanvas from "./SvgCanvas/SvgCanvas";
import useCustomSearchParams from "../../hooks/useCustomSearchParams";
import { useDispatch, useSelector } from "react-redux";
import { getFlyby } from "../../stores/actions";
import { fromTo,   fromToDecrease,   prepareArrayForDragging } from './helpers';


import './canvas.scss';

export default function Canvas({controlPoints, flyby, imgUrl, ...props}) {

   const activeFlyby = useSelector(store => store.flybyReducer[flyby]);
   const [polygonPoints, setPoly] = useState([]);
   const isPending = useSelector(store => !store.flybyReducer[flyby]);
   const dispatch = useDispatch();
//    useEffect(() => {
//       fetch('https://ozon-test.smarto.com.ua/wp-content/themes/ozon/assets/svg3d/svg_5_2022-04-03_17-59-04.svg')
//           .then(res=> res.text())
//           .then(res => {
//               const parser = new DOMParser();
//               const dom = parser.parseFromString(res, 'text/html');
//               const arr = Array.from(dom.querySelectorAll('polygon')).map(el => {
//                   return el.getAttribute('points')
//               });
//               setPoly(arr);
//               console.log(arr);
//           });
//   }, [])

   useEffect(() => {
      dispatch(getFlyby(flyby));
   }, [flyby]);
   const [currentPoint, setCurrentPoint] = useState(controlPoints[0] || 0);
   /**Активный индекс массива props.controlPoints */
   const [activeFrameIndex, setActiveFrame] = useState(0);
   /**Флаг для включения переключения кадров при перетаскивании */
   const [isClicked, setIsClicked]  = useState(false);
   const [startClickedX, setStartClickedX] = useState(0);
   const searchParams = useCustomSearchParams();
   // Состояние активного массива, из которого будут братся значения для вывода нужной картинки при перетаскивании
   const [preparedArrayForDragging , setPreparedArrayForDragging] = useState(prepareArrayForDragging(controlPoints.length - 1, 0, 1, 119));

   // useEffect(() => setCurrentPoint(searchParams.getSearchParam('currentPoint')), []);
   useEffect(() => {
      const nextIndexInControlPoints = (activeFrameIndex === controlPoints.length - 1) ? 0 : (activeFrameIndex + 1);
      const prevIndexInControlPoints = (activeFrameIndex === 0) ? controlPoints.length - 1 : activeFrameIndex - 1;
      const arr = prepareArrayForDragging(
         controlPoints[prevIndexInControlPoints], 
         controlPoints[activeFrameIndex], 
         controlPoints[nextIndexInControlPoints], 
         119 
      );
      setPreparedArrayForDragging(arr);
   },[activeFrameIndex]);
   useEffect(() => {
      setPoly([]);
      if (!controlPoints.includes) return;
      if (!controlPoints.includes(currentPoint)) return;
      searchParams.set('currentPoint', currentPoint)
      fetch(`https://grandbourget-test.smarto.com.ua/wp-content/themes/grand-byrze/assets/s3d/images/svg/genplan/${currentPoint}.svg`)
         .then(res=> res.text())
         .then(res => {
            const parser = new DOMParser();
            const dom = parser.parseFromString(res, 'text/html');
            const arr = Array.from(dom.querySelectorAll('polygon')).map(el => {
               return el.getAttribute('points')
            });
            setPoly(arr);
         })
      
   }, [currentPoint])
   useEffect(() =>  setCurrentPoint(controlPoints[0]), [controlPoints]);
   // useEffect(() => searchParams.set('currentPoint', currentPoint),[currentPoint]);
   
   return <>
      <div className="btn btn-success">{currentPoint}</div>
      <Row disabled>
         <Controls 
            isPending={isPending}
            onClick={() => {
               const nextActiveFramIndex = (activeFrameIndex === controlPoints.length - 1) ? 0 : activeFrameIndex+1;
               fromTo(controlPoints[activeFrameIndex], controlPoints[nextActiveFramIndex], (point) => {
                  setCurrentPoint(point);
                  setActiveFrame((activeFrameIndex === controlPoints.length - 1) ? 0 : activeFrameIndex+1);
               })
            }}
            onClickPrev={() => {
               const prevActiveFramIndex = (activeFrameIndex === 0) ? controlPoints.length - 1 : activeFrameIndex-1;
               fromToDecrease(controlPoints[activeFrameIndex], controlPoints[prevActiveFramIndex], (point) => {
                  setCurrentPoint(point);
                  setActiveFrame((activeFrameIndex === 0) ? controlPoints.length - 1 : activeFrameIndex-1);
               })
            }}
         />
      </Row>
      <SvgCanvas 
         currenturl={activeFlyby && activeFlyby[currentPoint]} 
         polygonPoints={polygonPoints} 
         isBlocked=""
         onMouseMove={(e) => {
            if (isClicked) {
               const frameValue = Math.floor(gsap.utils.mapRange(0, window.innerWidth, 0, preparedArrayForDragging.length-1,  e.clientX));
               setCurrentPoint(preparedArrayForDragging[frameValue]);
            }
         }} 
         onMouseDown={(e) => {
            setIsClicked(true);
            setStartClickedX(e.clientX)
   
         }} 
         onMouseUp={() =>{ 
            setIsClicked(false);
            setCurrentPoint(controlPoints[activeFrameIndex]);
         }}
      />
      {/* <CircularStatic progress={loadStatus} className="circular-progress"/> */}
      <Filter></Filter>
   </>
}

Canvas.propTypes = {
   /** Array of control points on which sequence will pause */
   controlPoints: PropTypes.arrayOf(PropTypes.number),
   /** url of images of current flyby, which will load */
   imgUrl: PropTypes.string,
   /** name of flyby */
   flyby: PropTypes.string
}