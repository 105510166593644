import PropTypes from 'prop-types';

import './controls.scss';
export default function Controls({isPending, ...props}) {


   return <div className={isPending ? "flyby-controls disabled" : "flyby-controls"}>
      <div onClick={props.onClickPrev} className="flyby-control flyby-control__prev">←</div>
      <div onClick={props.onClick} className="flyby-control flyby-control__next">→</div>
   </div>
}

Controls.propTypes = {
   onClick: PropTypes.func,
   onClickPrev: PropTypes.func,
}