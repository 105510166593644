export const convertURL2Obj = () => {
   let array = window.location.search.replace('?', '').split('&').map(el => el.split('='));
   let obj = {};
   array.forEach(el => obj[el[0]] = el[1]);
   return obj;
 }
 export const convertObj2URL = (obj) => {
   if(isEmpty(obj)) return '';
   
   let accStr = '?'
   for (var prop in obj) {
     accStr += `${prop}=${obj[prop]}&`
   }
   return accStr.slice(0, -1);
 }
 
 function isEmpty(obj) {
     return Object.keys(obj).length === 0;
 }