import { createStore } from 'redux';


function filteredFlatsReducer(state = [], action) {
   switch (action.type) {
      case 'filter':
         return state;
   
      default:
         return state;
   }
}

// const filteredFlatsStore = createStore(filteredReducer);
export default filteredFlatsReducer;